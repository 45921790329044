import { Fragment, useEffect, useState } from 'react';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import LineChart from '../charts/LineChart';
import InputErrorMessage from 'components/input/InputErrorMessage';
import { Lateralities } from 'constants.js';
import { capitalize } from 'utils/string';
import Loader from 'components/loader/Loader';
import { CardBody } from 'components/card';
import Message from 'components/message/Message';
import MessageText from 'components/message/MessageText';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { groupBy } from 'utils/array';

const SidePanelChartCard = ({
  title,
  subTitle = '',
  testItemId,
  laterality,
  chartData,
  errorMessage = '',
  dataSrc,
  dataSrcProps
}) => {
  const [chartDataState, setChartDataState] = useState(chartData);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function loadData() {
      const result = await dataSrc(dataSrcProps);
      setLoading(false);
      setLoaded(true);
      if (!result) return;

      const data = groupBy(result, ['laterality']);

      const chartLines = [];
      Object.values(data).forEach(item => {
        chartLines.push({
          id: `${testItemId}_${item[0].laterality}`,
          ...chartData,
          data: [...item]
        });
      });

      setChartDataState(chartLines);
    }
    if (dataSrc) {
      setLoading(true);
      loadData();
    }
    if (!dataSrc && chartData) {
      setChartDataState([chartData]);
      setLoaded(true);
    }
  }, []);

  const lateralitySubtitle =
    laterality && Number(laterality) !== 2
      ? capitalize(Lateralities[laterality])
      : '';

  const subtitleCard = (
    <>
      {subTitle}
      {laterality && Number(laterality) !== 2 && (
        <>- [{<b>{lateralitySubtitle}</b>}]</>
      )}
    </>
  );

  return (
    <>
      <CardHeader modal>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        <CardHeaderSubtitle>{subtitleCard}</CardHeaderSubtitle>
        <InputErrorMessage>{errorMessage}</InputErrorMessage>
      </CardHeader>
      {loaded && !chartDataState.length === 0 && (
        <CardBody extraClassNames="u-margin-bottom-none u-padding-vertical-huge">
          <Message emptyState icon="test">
            <MessageText>
              <FormattedMessage {...messages.emptySessionTestDataHistory} />
            </MessageText>
          </Message>
        </CardBody>
      )}

      {loading && <Loader />}
      {chartDataState.length > 0 && (
        <div style={{ height: 'calc(100% - 156px)', padding: '8px 24px' }}>
          <LineChart
            chartData={chartDataState}
            title={title}
            tooltipSubTitle={subtitleCard}
          />
        </div>
      )}
    </>
  );
};

export default SidePanelChartCard;
